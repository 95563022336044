import { Button } from 'antd';

export function getCheckerHistoryReportHeader(handleAction) {
  return [
    // {
    //   title: 'Customer',
    //   dataIndex: 'customer_name',
    //   key: 'customer_name',
    //   sorter: (a, b) => a?.customer_name?.localeCompare(b?.customer_name),
    //   fixed: 'left',
    // },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Qty sold',
      dataIndex: 'salesQty',
      key: 'salesQty',
      sorter: (a, b) => a.salesQty - b.salesQty,
    },
    // {
    //   title: 'Total amount',
    //   dataIndex: 'total_amount',
    //   key: 'total_amount',
    // },
    // {
    //   title: 'Amount paid',
    //   dataIndex: 'amount_paid',
    //   key: 'amount_paid',
    // },
    // {
    //   title: 'Type',
    //   dataIndex: 'transtype',
    //   key: 'transtype',
    //   sorter: (a, b) => a?.transtype?.localeCompare(b?.transtype),
    // },
    // {
    //   title: 'Price per KG',
    //   dataIndex: 'price_per_kg',
    //   key: 'price_per_kg',
    // },
    // {
    //   title: 'Order #',
    //   dataIndex: 'orderNumber',
    //   key: 'orderNumber',
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id)}
            className="mx-2"
          >
            View
          </Button>
        </>
      ),
    },
  ];
}
