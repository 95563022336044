import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HOME_ROUTE, SALES_ORDER_ROUTE } from './routes';
import { LoginPage, SalesOrder } from '../../pages';

export default function AuthRoute() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} exact={true} element={<LoginPage />} />
      {/* <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
