import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { Avatar, Button, Card, Col, List, Modal, Row, Typography } from 'antd';
import OutletDefaultType from '../../utils/types/OutletDefaultType';
import UserDefaultType from '../../utils/types/UserDefaultType';
import BranchDefaultType from '../../utils/types/BranchDefaultType';
import ProductDefaultType from '../../utils/types/ProductDefaultType';
import RetrievedOrderDefaultType from '../../utils/types/RetrievedOrderDefaultType';
import { getBranch } from '../../utils/api/BranchesAPI';
import { postOutletOrder } from '../../utils/api/OutletAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { Content } from 'antd/es/layout/layout';
import Meta from 'antd/es/card/Meta';
import { AiFillEdit } from 'react-icons/ai';
import OutletOrderRetrievalModal from './OutletOrderRetrievalModal';
import CheckerSelection from '../SalesEntry/CheckerSelection';
import { GlobalContext } from '../../App';

export default function OutletSalesEntry() {
  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const [outletInfo, setOutletInfo] = useState(OutletDefaultType);
  const [checkerInfo, setCheckerInfo] = useState(UserDefaultType);

  const [branchInfo, setBranchInfo] = useState(BranchDefaultType);
  const [orderInfo, setOrderInfo] = useState(RetrievedOrderDefaultType);
  const [tankInfo, setTankInfo] = useState(ProductDefaultType);

  const [isOrderRetrievalModalOpen, setIsOrderRetrievalModalOpen] =
    useState(false);

  const [isCheckerSelectionModalOpen, setIsCheckerSelectionModalOpen] =
    useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedProductState, setSelectedProductState] = useState({
    id: '',
    productName: '',
    productDesc: '',
    productQty: '',
    pricePerkg: '',
    productPriceperkg: '',
  });

  const handleOnRetrieveOrder = (order, outletName) => {
    const newOrderList = order.orderList.map((item, index) => {
      return { ...item, productPriceperkg: item.pricePerkg };
    });
    order = { ...order, orderList: newOrderList };
    setOrderInfo(order);
    setOutletInfo({ ...outletName, firstName: outletName });
    setIsCheckerSelectionModalOpen(true);
  };

  const handleCheckerCompletion = (checker, tank) => {
    setCheckerInfo(checker);
    setTankInfo(tank);
    setIsCheckerSelectionModalOpen(false);
    setIsLoading(false);
  };

  const fetchBranchDetails = async () => {
    const res = await getBranch(profile.branchCode);
    if (res.isSuccessful) {
      setBranchInfo(res.data[0]);
    }
  };

  const getTotalOrderQty = () => {
    const sum = orderInfo.orderList.reduce((accumulator, currentRecord) => {
      return accumulator + parseFloat(currentRecord.productQty);
    }, 0);
    return sum;
  };

  const handleSaveTransaction = async () => {
    setIsLoading(true);
    const payload = {
      orderNumber: orderInfo.orderNumber,
      cashier: profile.id,
      checker: checkerInfo.id,
      tankCode: tankInfo.id,
      productList: orderInfo.orderList.map((product) => ({
        productId: product.id,
        totalQty: product.productQty,
        productDisc: 0,
        productPriceperkg: product.productPriceperkg,
      })),
    };
    console.log(payload);
    const res = await postOutletOrder(payload);
    if (res.isSuccess) {
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Retail refill sales',
        'Transaction posted successfully!'
      );
      setIsConfirmationModalOpen(false);
      resetData();
      setIsOrderRetrievalModalOpen(true);
      setIsLoading(true);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Retail refill sales',
        getAPIResponseMessage(res.data)
      );
      setIsLoading(false);
    }
  };

  const resetData = () => {
    setCheckerInfo(UserDefaultType);
    setBranchInfo(BranchDefaultType);
    setOrderInfo(RetrievedOrderDefaultType);
    setTankInfo(ProductDefaultType);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchBranchDetails();
    if (orderInfo.orderNumber === '') {
      setIsOrderRetrievalModalOpen(true);
    }
  }, []);

  return (
    <Content>
      <Row gutter={16}>
        <Col xs={24} sm={6}>
          <Card
            hoverable
            title="Outlet Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={outletInfo.outlet_desc}
              description={outletInfo.outlet_address}
            />
          </Card>
          <Card
            hoverable
            title="Branch Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta title={branchInfo.name} description={branchInfo.address} />
          </Card>
          <Card
            hoverable
            title="Cashier Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => console.log('Change cashier')}
              />,
            ]}
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={profile.username}
              description={`${profile.firstName} ${profile.lastName}`}
            />
          </Card>
          <Card
            hoverable
            title="Checker Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => console.log('Change checker')}
              />,
            ]}
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={checkerInfo.username}
              description={`${checkerInfo.firstName} ${checkerInfo.lastName}`}
            />
          </Card>
        </Col>
        <Col xs={24} sm={18}>
          <Card hoverable loading={isLoading} className="mb-3">
            <Meta
              title={`Total Items: ${orderInfo.orderList.length}`}
              className="mb-2"
            />
            <Meta
              title={`Total Order Qty: ${getTotalOrderQty()} kgs.`}
              className="mb-2"
            />
            <Meta title={'Transaction type: Retail refill'} className="mb-2" />
          </Card>

          <Card loading={isLoading} hoverable title="Order Details">
            <List
              itemLayout="horizontal"
              dataSource={orderInfo.orderList.map((product) => ({
                id: product.id,
                title: product.productName,
                description: product.productDesc,
                orderQty: product.productQty,
              }))}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <Typography.Text strong>{item.title}</Typography.Text>
                    }
                    description={
                      <>
                        <Typography.Text strong>Description: </Typography.Text>
                        <Typography.Text>{item.description}</Typography.Text>
                        <br />
                        <Typography.Text strong>Order qty: </Typography.Text>
                        <Typography.Text>{item.orderQty} kg</Typography.Text>
                        <br />
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
          {!isLoading && (
            <Row justify="end" align="middle" className="my-3">
              <Button
                size="large"
                type="primary"
                onClick={() => setIsConfirmationModalOpen(true)}
              >
                Save Transaction
              </Button>
            </Row>
          )}
        </Col>
      </Row>

      <OutletOrderRetrievalModal
        isOpen={isOrderRetrievalModalOpen}
        setIsOpen={setIsOrderRetrievalModalOpen}
        onSelectOrder={handleOnRetrieveOrder}
      />

      <CheckerSelection
        isOpen={isCheckerSelectionModalOpen}
        setIsOpen={setIsCheckerSelectionModalOpen}
        handleOnFinishSelection={handleCheckerCompletion}
      />

      <Modal
        centered
        maskClosable={false}
        closable={false}
        title="Please confirm order."
        open={isConfirmationModalOpen}
        onOk={() => handleSaveTransaction()}
        onCancel={() => setIsConfirmationModalOpen(false)}
        okText="Confirm"
      ></Modal>
    </Content>
  );
}
