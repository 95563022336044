import axios from 'axios';
import {
  API_CALL_ERROR,
  LOGIN_ENDPOINT,
  REGISTER_ENDPOINT,
  GET_USERS_BY_BRANCH_ENDPOINT,
  DEACTIVATE_USER_ENDPOINT,
  GET_USER_BY_ID_ENDPOINT,
  USER_CHANGE_PASSWORD_ENDPOINT,
} from './_ConstantsAPI';

export async function login(username, password) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const payload = {
      username: username,
      password: password,
    };

    const { data } = await axios.post(LOGIN_ENDPOINT, payload, { headers });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function register(requestorId, payload, authToken) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(REGISTER_ENDPOINT, payload, {
      params: { requestorId, authToken },
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function deactivateUser(requestorId, payload, authToken) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  try {
    const { data } = await axios.post(DEACTIVATE_USER_ENDPOINT, payload, {
      params: { requestorId, authToken },
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getUsersByBranch(requestorId, branchCode, authToken) {
  try {
    const { data } = await axios.get(GET_USERS_BY_BRANCH_ENDPOINT, {
      params: {
        branchCode: branchCode,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getUserById(requestorId, userId, authToken) {
  try {
    const { data } = await axios.get(GET_USER_BY_ID_ENDPOINT, {
      params: {
        userId: userId,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function changePassword(requestorId, payload, authToken) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(USER_CHANGE_PASSWORD_ENDPOINT, payload, {
      params: { requestorId, authToken },
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
