import { Button, Form, Input, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './LoginPage.css';
import { login } from '../../utils/api/UsersAPI';
import { getAPIResponseMessage } from '../../utils/api/_ConstantsAPI';
import { GlobalContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../utils/router/routes';
import { UserContext } from '../../utils/context/UserContext';
import Cookies from 'js-cookie';

export default function LoginPage() {
  const { setIsLoggedIn, setAuthToken, encryptValue, decryptValue } =
    useContext(GlobalContext);
  const { setProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const cookiesSettings = {
    expires: 1,
    secure: true,
    sameSite: 'strict',
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const res = await login(values.username, values.password);
    if (res.isSuccessful) {
      Cookies.set('username', encryptValue(values.username), cookiesSettings);
      Cookies.set('password', encryptValue(values.password), cookiesSettings);
      Cookies.set('is-logged-in', true, cookiesSettings);

      setAuthToken(res.authToken.token);
      setProfile(res.data);
      navigate(HOME_ROUTE);
      setIsLoggedIn(true);
    } else {
      messageApi.error(getAPIResponseMessage(res.data));
    }
    setIsLoading(false);
  };

  const handleAutoLogin = async () => {
    setIsLoading(true);
    const isLoggedIn = Cookies.get('is-logged-in');
    if (isLoggedIn) {
      const username = decryptValue(Cookies.get('username'));
      const password = decryptValue(Cookies.get('password'));
      const res = await login(username, password);
      if (res.isSuccessful) {
        setAuthToken(res.authToken.token);
        setProfile(res.data);
        navigate(HOME_ROUTE);
        setIsLoggedIn(true);
      } else {
        messageApi.error(getAPIResponseMessage(res.data));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  return (
    <div className="Auth-form-container">
      {contextHolder}
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="on"
        size="large"
        className="Auth-form p-5"
        disabled={isLoading}
      >
        <h3 className="text-white text-center">Sign In</h3>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input placeholder="Username" className="p-3" autoFocus={true} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" className="p-3" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" className="mt-3">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
