import axios from 'axios';
import { API_CALL_ERROR, TIME_IN_OUT_ENDPOINT } from './_ConstantsAPI';

export async function timeInOutAPI(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const { data } = await axios.post(TIME_IN_OUT_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
