import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { getDailyStocksReportAPI } from '../../utils/api/ReportsAPI';
import DailyStocksDefaultType from '../../utils/types/DailyStocksDefaultType';
import { GlobalContext } from '../../App';
import {
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../utils/api/_ConstantsAPI';
import { PrinterFilled } from '@ant-design/icons';
import { PDFViewer } from '@react-pdf/renderer';
import DailyStocksReportPrint from '../../components/PDFDocuments/DailyStocksReportPrint';

export default function DailyStocksReport() {
  const { profile, getCurrentDate } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState('');
  const [localDailyStocksReport, setLocalDailyStocksReport] = useState(
    DailyStocksDefaultType
  );

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const getDailyStocksReport = async () => {
    setIsLoading(true);
    const res = await getDailyStocksReportAPI(selectedDate, profile.branchCode);
    if (res.isSuccess) {
      setLocalDailyStocksReport(res.dsr);
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Daily stocks report',
        `Showing daily stocks report for ${selectedDate}!`
      );
    } else {
      openNotification(
        WARNING_NOTIFICATION_TYPE,
        'Daily stocks report',
        'Something went wrong!'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <Content>
      <Typography.Title level={3}>Daily stocks report</Typography.Title>
      <Row className="mb-3">
        <Col sm={18} xs={24} className="text-start">
          <Space.Compact>
            <DatePicker
              onChange={(_, dateString) => {
                setSelectedDate(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <Button
              type="primary"
              disabled={isLoading || selectedDate === ''}
              onClick={() => getDailyStocksReport()}
            >
              Process
            </Button>
          </Space.Compact>
        </Col>
        <Col sm={6} xs={24}>
          <Button
            block
            icon={<PrinterFilled />}
            onClick={() => setIsPrintModalOpen(true)}
            disabled={localDailyStocksReport?.date === null || isLoading}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={18}>
          <Card hoverable bordered>
            {localDailyStocksReport.date !== null ? (
              <React.Fragment>
                <Descriptions
                  bordered
                  size="small"
                  column={{ sm: 2, xs: 1 }}
                  className="mb-2"
                >
                  <Descriptions.Item label="Date">
                    {localDailyStocksReport.date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cashier">
                    {`${profile.firstName} ${profile.lastName}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Opening Grandtotal sales">
                    {localDailyStocksReport.oldgt}
                  </Descriptions.Item>
                  <Descriptions.Item label="Closing Grandtotal sales">
                    {localDailyStocksReport.newgt}
                  </Descriptions.Item>
                  <Descriptions.Item label="Opening Inventory">
                    {localDailyStocksReport.oldinventory}
                  </Descriptions.Item>
                  <Descriptions.Item label="Closing Inventory">
                    {localDailyStocksReport.newinventory}
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  column={{ xs: 2, sm: 2 }}
                  className="mb-2"
                >
                  <Descriptions.Item
                    label="OPR RETAIL"
                    span={2}
                    className="pb-0"
                  />
                  <Descriptions.Item label="SALES IN KG">
                    {localDailyStocksReport.oprRetailKg} KG
                  </Descriptions.Item>
                  <Descriptions.Item label="SALES IN PESO">
                    &#8369; {localDailyStocksReport.oprRetailSales}
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  column={{ xs: 2, sm: 2 }}
                  className="mb-2"
                >
                  <Descriptions.Item
                    label="OPR DEALER"
                    span={2}
                    className="pb-0"
                  />
                  <Descriptions.Item label="SALES IN KG">
                    {localDailyStocksReport.oprDealerKg} KG
                  </Descriptions.Item>
                  <Descriptions.Item label="SALES IN PESO">
                    &#8369; {localDailyStocksReport.oprDealerSales}
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  column={{ xs: 2, sm: 2 }}
                  className="mb-2"
                >
                  <Descriptions.Item label="RETAIL" span={2} className="pb-0" />
                  <Descriptions.Item label="SALES IN KG">
                    {localDailyStocksReport.retailrefill} KG
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  column={{ xs: 3, sm: 3 }}
                  className="mb-2"
                >
                  <Descriptions.Item
                    label="AVE. PRICE"
                    span={3}
                    className="pb-0"
                  />
                  <Descriptions.Item label="TOTAL SALES IN KG">
                    {parseFloat(localDailyStocksReport.oprRetailKg) +
                      parseFloat(localDailyStocksReport.oprDealerKg)}{' '}
                    KG
                  </Descriptions.Item>
                  <Descriptions.Item label="TOTAL SALES IN PESO">
                    &#8369; {localDailyStocksReport.totalSales}
                  </Descriptions.Item>
                  <Descriptions.Item label="AVERAGE">
                    {(
                      (parseFloat(localDailyStocksReport.oprRetailKg) +
                        parseFloat(localDailyStocksReport.oprDealerKg)) /
                      parseFloat(localDailyStocksReport.totalSales)
                    ).toLocaleString()}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  column={{ xs: 2, sm: 2 }}
                  className="mb-2"
                >
                  <Descriptions.Item
                    label="TOTAL SALES"
                    span={2}
                    className="pb-0"
                  />
                  <Descriptions.Item label="TOTAL SALES IN KG">
                    {parseFloat(localDailyStocksReport.totalKg)} KG
                  </Descriptions.Item>
                  <Descriptions.Item label="TOTAL SALES IN PESO">
                    &#8369; {localDailyStocksReport.totalSales}
                  </Descriptions.Item>
                </Descriptions>
              </React.Fragment>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        centered
        closable={false}
        open={isPrintModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsPrintModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'90vw'}
      >
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <DailyStocksReportPrint
            dsr={localDailyStocksReport}
            profile={profile}
          />
        </PDFViewer>
      </Modal>
    </Content>
  );
}
