import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_EXPENSE_CATEGORY_ENDPOINT,
  GET_EXPENSE_LIST_ENDPOINT,
  GET_OUTLET_LIST_ENDPOINT,
  GET_OUTLET_ORDER_ENDPOINT,
  POST_EXPENSE_REQUEST_ENDPOINT,
  POST_OUTLET_ORDER_ENDPOINT,
  POST_RETURN_EXPENSE_ENDPOINT,
  SAVE_OUTLET_ORDER_ENDPOINT,
} from './_ConstantsAPI';

export async function getOutletList() {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.get(GET_OUTLET_LIST_ENDPOINT, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function saveOutletOrder(params) {
  const payload = {
    data: JSON.stringify(params),
  };
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(SAVE_OUTLET_ORDER_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getOutletOrder(orderNumber, branchId) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.get(
      `${GET_OUTLET_ORDER_ENDPOINT}/${orderNumber}/${branchId}`,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postOutletOrder(params) {
  const payload = {
    data: JSON.stringify(params),
  };
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(POST_OUTLET_ORDER_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getOutletExpenseCategories() {
  try {
    const { data } = await axios.get(GET_EXPENSE_CATEGORY_ENDPOINT);
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postOutletExpenseRequest(payload) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(POST_EXPENSE_REQUEST_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getOutletExpenseAPI() {
  try {
    const { data } = await axios.get(GET_EXPENSE_LIST_ENDPOINT);
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postReturnOutletExpenseAPI(payload) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(POST_RETURN_EXPENSE_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
