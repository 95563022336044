import {
  dashboardMenuNavigation,
  endOfDayNavigation,
  inventorySubMenuNavigation,
  logoutNavigation,
  maintenanceSubMenuNavigation,
  outletSubMenuNavigation,
  reportSubMenuNavigation,
  salesEntryMenuNavigation,
  salesOrderMenuNavigation,
  solidAssetsSalesEntry,
  timekeepingMenuNavigation,
  expensRequestNavigation,
} from '../../utils/router/navigation';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const adminItems = [
  getItem(
    dashboardMenuNavigation.title,
    dashboardMenuNavigation.path,
    dashboardMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesOrderMenuNavigation.title,
    salesOrderMenuNavigation.path,
    salesOrderMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesEntryMenuNavigation.title,
    salesEntryMenuNavigation.path,
    salesEntryMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    solidAssetsSalesEntry.title,
    solidAssetsSalesEntry.path,
    solidAssetsSalesEntry.icon,
    null,
    null
  ),
  getItem(
    endOfDayNavigation.title,
    endOfDayNavigation.path,
    endOfDayNavigation.icon,
    null,
    null
  ),
  getItem(
    expensRequestNavigation.title,
    expensRequestNavigation.path,
    expensRequestNavigation.icon,
    null,
    null
  ),
  getItem(
    reportSubMenuNavigation.title,
    reportSubMenuNavigation.id,
    reportSubMenuNavigation.icon,
    reportSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    maintenanceSubMenuNavigation.title,
    maintenanceSubMenuNavigation.id,
    maintenanceSubMenuNavigation.icon,
    maintenanceSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    outletSubMenuNavigation.title,
    outletSubMenuNavigation.id,
    outletSubMenuNavigation.icon,
    outletSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    inventorySubMenuNavigation.title,
    inventorySubMenuNavigation.id,
    inventorySubMenuNavigation.icon,
    inventorySubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    timekeepingMenuNavigation.title,
    timekeepingMenuNavigation.path,
    timekeepingMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    logoutNavigation.title,
    logoutNavigation.path,
    logoutNavigation.icon,
    null,
    null
  ),
];

export const poItems = [
  getItem(
    dashboardMenuNavigation.title,
    dashboardMenuNavigation.path,
    dashboardMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesOrderMenuNavigation.title,
    salesOrderMenuNavigation.path,
    salesOrderMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesEntryMenuNavigation.title,
    salesEntryMenuNavigation.path,
    salesEntryMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    solidAssetsSalesEntry.title,
    solidAssetsSalesEntry.path,
    solidAssetsSalesEntry.icon,
    null,
    null
  ),
  getItem(
    endOfDayNavigation.title,
    endOfDayNavigation.path,
    endOfDayNavigation.icon,
    null,
    null
  ),
  getItem(
    expensRequestNavigation.title,
    expensRequestNavigation.path,
    expensRequestNavigation.icon,
    null,
    null
  ),
  getItem(
    reportSubMenuNavigation.title,
    reportSubMenuNavigation.id,
    reportSubMenuNavigation.icon,
    reportSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    maintenanceSubMenuNavigation.title,
    maintenanceSubMenuNavigation.id,
    maintenanceSubMenuNavigation.icon,
    maintenanceSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    outletSubMenuNavigation.title,
    outletSubMenuNavigation.id,
    outletSubMenuNavigation.icon,
    outletSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    inventorySubMenuNavigation.title,
    inventorySubMenuNavigation.id,
    inventorySubMenuNavigation.icon,
    inventorySubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    timekeepingMenuNavigation.title,
    timekeepingMenuNavigation.path,
    timekeepingMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    logoutNavigation.title,
    logoutNavigation.path,
    logoutNavigation.icon,
    null,
    null
  ),
];

export const cashierItems = [
  getItem(
    dashboardMenuNavigation.title,
    dashboardMenuNavigation.path,
    dashboardMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesOrderMenuNavigation.title,
    salesOrderMenuNavigation.path,
    salesOrderMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesEntryMenuNavigation.title,
    salesEntryMenuNavigation.path,
    salesEntryMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    solidAssetsSalesEntry.title,
    solidAssetsSalesEntry.path,
    solidAssetsSalesEntry.icon,
    null,
    null
  ),
  getItem(
    endOfDayNavigation.title,
    endOfDayNavigation.path,
    endOfDayNavigation.icon,
    null,
    null
  ),
  getItem(
    expensRequestNavigation.title,
    expensRequestNavigation.path,
    expensRequestNavigation.icon,
    null,
    null
  ),
  getItem(
    reportSubMenuNavigation.title,
    reportSubMenuNavigation.id,
    reportSubMenuNavigation.icon,
    reportSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    maintenanceSubMenuNavigation.title,
    maintenanceSubMenuNavigation.id,
    maintenanceSubMenuNavigation.icon,
    maintenanceSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    outletSubMenuNavigation.title,
    outletSubMenuNavigation.id,
    outletSubMenuNavigation.icon,
    outletSubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    inventorySubMenuNavigation.title,
    inventorySubMenuNavigation.id,
    inventorySubMenuNavigation.icon,
    inventorySubMenuNavigation.items.map((menuItem) =>
      getItem(menuItem.title, menuItem.path, null, null, null)
    )
  ),
  getItem(
    timekeepingMenuNavigation.title,
    timekeepingMenuNavigation.path,
    timekeepingMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    logoutNavigation.title,
    logoutNavigation.path,
    logoutNavigation.icon,
    null,
    null
  ),
];

export const checkerItems = [
  getItem(
    dashboardMenuNavigation.title,
    dashboardMenuNavigation.path,
    dashboardMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    salesOrderMenuNavigation.title,
    salesOrderMenuNavigation.path,
    salesOrderMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    timekeepingMenuNavigation.title,
    timekeepingMenuNavigation.path,
    timekeepingMenuNavigation.icon,
    null,
    null
  ),
  getItem(
    logoutNavigation.title,
    logoutNavigation.path,
    logoutNavigation.icon,
    null,
    null
  ),
];
