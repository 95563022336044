import {
  Badge,
  Button,
  Card,
  Col,
  Image,
  InputNumber,
  List,
  Modal,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { getProducts } from '../../utils/api/ProductsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import Meta from 'antd/es/card/Meta';
import { BsFillCartFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { GlobalContext } from '../../App';

export default function ContainerSelection({ cart, setCart }) {
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);

  const [localProductsList, setLocalProductsList] = useState([]);

  const [selectedProductState, setSelectedProductState] = useState({});
  const [selectedProductQty, setSelectedProductQty] = useState(0);

  const fetchAllProducts = async () => {
    setIsLoading(true);
    const res = await getProducts();
    if (res.isSuccessful) {
      setLocalProductsList(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Select container',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (localProductsList.length === 0) {
      fetchAllProducts();
    }
  }, []);

  return (
    <Content>
      {isLoading && (
        <Row justify="center" align="middle">
          <Spin size="large" />
        </Row>
      )}
      {!isLoading && (
        <>
          <Row>
            <Col xs={24} className="text-end">
              {cart.length > 0 && (
                <Badge size="large" count={cart.length}>
                  <Tooltip title="Cart">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<BsFillCartFill size={24} />}
                      size="large"
                      className="mb-3"
                      onClick={() => setIsCartModalOpen(true)}
                    />
                  </Tooltip>
                </Badge>
              )}
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            {localProductsList
              .filter((data) => data.isTank !== '1')
              .filter((data) => data.categoryId !== '3')
              .map((product, index) => (
                <Col xs={24} sm={12} md={8} lg={6} key={index}>
                  <Card
                    hoverable
                    cover={
                      <img
                        alt="product"
                        src={
                          product.image === ''
                            ? 'https://kadiwa-sais.xgitcorp.com/lpg-default-pic.png'
                            : product.image
                        }
                      />
                    }
                    onClick={() => {
                      setSelectedProductState(product);
                      setSelectedProductQty(parseFloat(product.quantity));
                      setIsAddToCartModalOpen(true);
                    }}
                  >
                    <Meta title={product.name} className="mb-2" />
                    <Meta
                      description={`Description: ${product.description}`}
                      className="mb-2"
                    />
                    <Meta
                      description={`Category: ${product.category}`}
                      className="mb-2"
                    />
                    <Meta
                      description={`KG Capacity: ${product.quantity}`}
                      className="mb-2"
                    />
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
      <Modal
        centered
        maskClosable={false}
        closable={false}
        title="Add to cart?"
        open={isAddToCartModalOpen}
        onOk={() => {
          const order = {
            product: selectedProductState,
            orderQty: selectedProductQty,
          };
          setCart((oldCart) => [...oldCart, order]);
          setIsAddToCartModalOpen(false);
        }}
        onCancel={() => setIsAddToCartModalOpen(false)}
        okText="Add"
      >
        <Typography.Text strong>
          Please enter how many KG you want your tank to be refilled.
        </Typography.Text>
        <Space className="my-2">
          <InputNumber
            min={1}
            max={parseFloat(selectedProductState.quantity)}
            type="number"
            controls={false}
            value={selectedProductQty}
            style={{
              width: '100%',
            }}
            onChange={(value) => {
              setSelectedProductQty(value);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              setSelectedProductQty(parseFloat(selectedProductState.quantity));
            }}
          >
            Max
          </Button>
        </Space>
        <br />
        <Typography.Text type="secondary">
          Maximum value is {selectedProductState.quantity} kg.
        </Typography.Text>
      </Modal>

      <Modal
        centered
        title={`Cart (${cart.length})`}
        open={isCartModalOpen}
        onOk={() => {
          setCart([]);
          setIsCartModalOpen(false);
        }}
        onCancel={() => setIsCartModalOpen(false)}
        cancelText="Close"
        okButtonProps={{
          danger: true,
          icon: <AiFillDelete size={24} />,
        }}
        okText="Empty Cart"
      >
        <List
          itemLayout="horizontal"
          dataSource={cart.map((product) => ({
            title: product.product.name,
            description: product.product.description,
            image: product.product.image,
            orderQty: product.orderQty,
          }))}
          renderItem={(item, index) => (
            <List.Item
              key={index}
              actions={[
                <Tooltip title="Remove order.">
                  <Button
                    danger
                    type="primary"
                    size="large"
                    onClick={() => {
                      const newCart = [...cart];
                      const filteredNewCart = newCart.filter(
                        (cart, idx) => idx !== index
                      );
                      setCart(filteredNewCart);
                    }}
                  >
                    <AiFillDelete size={24} />
                  </Button>
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Image
                    style={{
                      maxWidth: '120px',
                    }}
                    src={
                      item.image === ''
                        ? 'https://kadiwa-sais.xgitcorp.com/lpg-default-pic.png'
                        : item.image
                    }
                  />
                }
                title={<Typography.Text strong>{item.title}</Typography.Text>}
                description={
                  <>
                    <Typography.Text strong>Description: </Typography.Text>
                    <Typography.Text>{item.description}</Typography.Text>
                    <br />
                    <Typography.Text strong>Order qty: </Typography.Text>
                    <Typography.Text>{item.orderQty} kg</Typography.Text>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </Content>
  );
}
