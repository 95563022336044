export default {
  id: '',
  firstName: '',
  lastName: '',
  accessDescription: '',
  accessLevel: '',
  branchCode: '',
  updatedBy: '',
  username: '',
  isDeleted: '',
  password: '',
};
