import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_INCOMING_HISTORY_ENDPOINT,
  GET_RUNNING_INVENTORY_ENDPOINT,
  POST_BULLET_INCOMING_ENDPOINT,
} from './_ConstantsAPI';

export async function getRunningInventory(tankCode) {
  const payload = {
    tankCode: tankCode,
  };
  try {
    const { data } = await axios.post(GET_RUNNING_INVENTORY_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getIncomingHistory(
  branchCode,
  tankCode,
  dateFrom,
  dateTo
) {
  try {
    const { data } = await axios.get(
      `${GET_INCOMING_HISTORY_ENDPOINT}/${branchCode}` +
        `/${dateFrom}/${dateTo}` +
        `/${tankCode}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postBulletIncoming(payload) {
  try {
    const { data } = await axios.post(POST_BULLET_INCOMING_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
