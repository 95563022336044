import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import CustomerDefaultType from '../../utils/types/CustomerDefaultType';
import { addEditCustomer, getCustomers } from '../../utils/api/CustomersAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { Content } from 'antd/es/layout/layout';
import { IoIosRefresh } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import { getCustomersTable } from '../../utils/tableData/Maintenance/CustomersTable';
import { getCustomerMaintenanceFields } from '../../utils/data/CustomerField';
import { FormInput } from '../../components';
import Search from 'antd/es/input/Search';
import { GlobalContext } from '../../App';

export default function CustomerMaintenance() {
  const { profile, formItemLayout } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [customerState, setCustomerState] = useState(CustomerDefaultType);

  const [localCustomersList, setLocalCustomersList] = useState([]);

  const [tableHeaderFilter, setTableHeaderFilter] = useState('');
  const [tableFilter, setTableFilter] = useState('');

  const tableContents = localCustomersList
    .filter((data) => {
      if (tableHeaderFilter === '') {
        return data;
      }
      return data[tableHeaderFilter]
        .toString()
        .toLowerCase()
        .includes(tableFilter.toLowerCase());
    })
    .map((data, index) => ({
      ...data,
      key: index,
    }));

  const handleAction = async (id, action) => {
    setIsLoading(true);
    if (action === 'edit') {
      const customer = localCustomersList.find((cust) => cust.id === id);
      setCustomerState({ ...customer });
      form.setFieldsValue(customer);
      setIsModalOpen(true);
    } else if (action === 'delete') {
      console.log('delete');
    }
    setIsLoading(false);
  };

  const handleModalSave = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();

      const res = await addEditCustomer(profile.id, customerState);
      if (res.isSuccessful) {
        setIsModalOpen(false);
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Customer maintenance',
          getAPIResponseMessage(res.data)
        );
        fetchCustomersList();
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Customer maintenance',
          getAPIResponseMessage(res.data)
        );
      }
    } catch (error) {
      // No action for now
    }
    setIsLoading(false);
  };

  const fetchCustomersList = async () => {
    setIsLoading(true);
    resetData();

    const res = await getCustomers();
    if (res.isSuccessful) {
      setLocalCustomersList(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Customer maintenance',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const resetData = () => {
    setTableHeaderFilter('');
    setTableFilter('');
    setCustomerState(CustomerDefaultType);
    form.setFieldsValue(CustomerDefaultType);
  };

  useEffect(() => {
    if (localCustomersList.length === 0) {
      fetchCustomersList();
    }
  }, []);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      fetchCustomersList();
    }, 1800000);

    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  return (
    <Content>
      <Row align={'middle'} className="mb-4">
        <Col xs={24} md={12}>
          <Typography.Title level={3}>Customer Maintenance</Typography.Title>
        </Col>
        <Col xs={24} md={12} className="text-end">
          <Button
            type="primary"
            size="large"
            icon={<IoIosRefresh size={24} />}
            loading={isLoading}
            onClick={() => fetchCustomersList()}
          />
          <Button
            type="primary"
            size="large"
            icon={<AiOutlinePlus size={24} />}
            loading={isLoading}
            className="ms-3"
            onClick={async () => {
              resetData();
              setIsModalOpen(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Space.Compact>
          <Select
            style={{ minWidth: '10vw' }}
            placeholder="Filters"
            value={tableHeaderFilter}
            onChange={(e) => {
              setTableHeaderFilter(e);
            }}
            disabled={isLoading}
            options={getCustomersTable(null)
              .filter((header) => header.key !== 'actions')
              .map((option) => ({
                label: option.title,
                value: option.key,
              }))}
          />
          <Search
            placeholder="Search for customer"
            disabled={isLoading || tableHeaderFilter === ''}
            value={tableFilter}
            onSearch={(value) => {
              setTableFilter(value);
            }}
            onChange={(e) => {
              const searchValue = e.target.value;
              setTableFilter(searchValue);
            }}
            allowClear={true}
          />
        </Space.Compact>
      </Row>
      <Table
        columns={getCustomersTable(handleAction)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <Modal
        title="Customer Entry"
        centered
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleModalSave}
        okText="Save"
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="customer_form"
          {...formItemLayout}
          initialValues={customerState}
        >
          {getCustomerMaintenanceFields(
            isLoading,
            customerState,
            setCustomerState
          ).map((data, index) => (
            <FormInput
              key={index}
              type={data.type}
              name={data.name}
              placeholder={data.placeholder}
              label={data.label}
              autoFocus={data.autofocus}
              value={data.value}
              onChange={data.setValue}
              required={data.required}
              disabled={data.disabled}
              dropdownOptions={data.dropdownOptions}
            />
          ))}
        </Form>
      </Modal>
    </Content>
  );
}
