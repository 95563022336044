import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import EndOfDaySummaryDefaultType from '../../utils/types/EndOfDaySummaryDefaultType';
import TenderDefaultType from '../../utils/types/TenderDefaultType';
import {
  getTenderDeclarationFirstColumnFields,
  getTenderDeclarationSecondColumnFields,
} from '../../utils/data/TenderDeclarationField';
import { FormInput } from '../../components';
import {
  postEODApi,
  postTenderDeclaration,
} from '../../utils/api/TransactionAPI';
import { GlobalContext } from '../../App';
import { ERROR_NOTIFICATION_TYPE } from '../../utils/api/_ConstantsAPI';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
  },
};

export default function EodSummary({
  summary = EndOfDaySummaryDefaultType,
  denomination = TenderDefaultType,
  denominationTotal,
  onCompleteEodProcess,
}) {
  const { profile, getCurrentDate } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [form_tender] = Form.useForm();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

  const [oprDetailsState, setOprDetailsState] = useState({
    opr_r: null,
    opr_d: null,
  });

  const handleConfirmation = async () => {
    setIsConfirmationLoading(true);

    const tenderDeclarationPayload = {
      ...denomination,
      branch: profile.branchCode,
      addedby: profile.id,
    };

    const eodPayload = {
      branch: profile.branchCode,
      postedby: profile.id,
      oprr: oprDetailsState.opr_r,
      oprd: oprDetailsState.opr_d,
    };

    const tenderRes = await postTenderDeclaration(tenderDeclarationPayload);
    if (tenderRes.isSuccess) {
      const eodRes = await postEODApi(eodPayload);
      if (eodRes.isSuccess) {
        const response = {
          newgt: eodRes.newgt,
          newgtinv: eodRes.newgtinv,
          oldgt: eodRes.oldgt,
          oldinv: eodRes.oldinv,
        };
        onCompleteEodProcess(oprDetailsState, response);
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'End of day!',
          'Error posting end of day! Please contact system administrator.'
        );
      }
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'End of day!',
        'Error saving tender declaration! Please contact system administrator.'
      );
    }
    setIsConfirmationLoading(false);
    setIsConfirmationModalOpen(false);
  };

  return (
    <Content>
      <Row gutter={32}>
        <Col xs={24} sm={12}>
          <Card hoverable bordered>
            <Row className="mb-3">
              <Col xs={12}>
                <Typography.Text type="secondary">
                  CASHIER:{' '}
                  <Typography.Text
                    strong
                  >{`${profile.firstName} ${profile.lastName}`}</Typography.Text>
                </Typography.Text>
              </Col>
              <Col xs={12}>
                <Typography.Text type="secondary">
                  DATE:{' '}
                  <Typography.Text strong>{getCurrentDate()}</Typography.Text>
                </Typography.Text>
              </Col>
            </Row>
            <Form form={form} name="opr_form">
              <Row gutter={12}>
                <Col xs={12}>
                  <Form.Item
                    name="opr_r"
                    label="OPR-R#"
                    rules={[
                      { required: true, message: 'This field is required.' },
                    ]}
                  >
                    <Input
                      placeholder="OPR-R#"
                      autoFocus={true}
                      value={oprDetailsState.opr_r}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOprDetailsState({
                          ...oprDetailsState,
                          opr_r: value,
                        });
                      }}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="opr_d"
                    label="OPR-D#"
                    rules={[
                      { required: true, message: 'This field is required.' },
                    ]}
                  >
                    <Input
                      placeholder="OPR-D#"
                      autoFocus={false}
                      value={oprDetailsState.opr_d}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOprDetailsState({
                          ...oprDetailsState,
                          opr_d: value,
                        });
                      }}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="mb-3">
              <Col xs={12}>
                <Typography.Text type="secondary">
                  OPR-R:{' '}
                  <Typography.Text strong>
                    {summary.retailSales === null ? '0' : summary.retailSales}
                  </Typography.Text>
                </Typography.Text>
              </Col>
              <Col xs={12}>
                <Typography.Text type="secondary">
                  OPR-D:{' '}
                  <Typography.Text strong>
                    {summary.wholesaleSales === null
                      ? '0'
                      : summary.wholesaleSales}
                  </Typography.Text>
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <Typography.Text type="secondary">
                  TOTAL:{' '}
                  <Typography.Text strong>{summary.totalSales}</Typography.Text>
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  onClick={async () => {
                    try {
                      await form.validateFields();
                      setIsConfirmationModalOpen(true);
                    } catch (error) {
                      //
                    }
                  }}
                >
                  Confirm!
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card hoverable bordered>
            <Typography.Title level={4}>Denomination</Typography.Title>
            <Row className="mb-3">
              <Col xs={24} sm={12}>
                <Space direction="horizontal">
                  <Typography.Text strong>Total: </Typography.Text>
                  <Typography.Title level={4} type="success">
                    {denominationTotal}
                  </Typography.Title>
                </Space>
              </Col>
            </Row>
            <Form
              form={form_tender}
              name="tender_form"
              {...formItemLayout}
              initialValues={denomination}
            >
              <Row gutter={32}>
                <Col xs={24} sm={12}>
                  {getTenderDeclarationFirstColumnFields(
                    false,
                    denomination,
                    null
                  ).map((data, index) => (
                    <FormInput
                      key={index}
                      type={data.type}
                      name={data.name}
                      placeholder={data.placeholder}
                      label={data.label}
                      autoFocus={data.autofocus}
                      value={data.value}
                      onChange={data.setValue}
                      required={false}
                      disabled={true}
                      dropdownOptions={data.dropdownOptions}
                    />
                  ))}
                </Col>
                <Col xs={24} sm={12}>
                  {getTenderDeclarationSecondColumnFields(
                    false,
                    denomination,
                    null
                  ).map((data, index) => (
                    <FormInput
                      key={index}
                      type={data.type}
                      name={data.name}
                      placeholder={data.placeholder}
                      label={data.label}
                      autoFocus={data.autofocus}
                      value={data.value}
                      onChange={data.setValue}
                      required={false}
                      disabled={true}
                      dropdownOptions={data.dropdownOptions}
                    />
                  ))}
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Confirm EOD Process!"
        centered
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={isConfirmationModalOpen}
        onOk={handleConfirmation}
        confirmLoading={isConfirmationLoading}
        onCancel={() => setIsConfirmationModalOpen(false)}
      >
        {/* <p>Please confirm the </p> */}
      </Modal>
    </Content>
  );
}
