import { Button, Popconfirm } from 'antd';

export function getOutletExpenseTableHeader(handleAction) {
  return [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
    },
    {
      title: 'Created By',
      dataIndex: 'addedby',
      key: 'addedby',
      sorter: (a, b) => a.addedby.localeCompare(b.addedby),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Popconfirm
            title={`Are you sure to update ${record.voucher}?`}
            onConfirm={() => handleAction(record.id)}
          >
            <Button type="primary">Update</Button>
          </Popconfirm>
        </>
      ),
    },
  ];
}
