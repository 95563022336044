import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../utils/context/UserContext';
import RetrievedOrderDefaultType from '../../utils/types/RetrievedOrderDefaultType';
import { getOutletOrder } from '../../utils/api/OutletAPI';
import { Button, Card, Col, Modal, Row, Typography } from 'antd';
import Search from 'antd/es/transfer/search';
import Meta from 'antd/es/card/Meta';
import { Content } from 'antd/es/layout/layout';
import { SALES_ORDER_OUTLET_ROUTE } from '../../utils/router/routes';

export default function OutletOrderRetrievalModal({
  isOpen,
  setIsOpen,
  onSelectOrder,
}) {
  const navigate = useNavigate();

  const { profile } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [selectedOrder, setSelectedOrder] = useState(RetrievedOrderDefaultType);
  const [outletName, setOutletName] = useState('');

  const handleSalesOrderSearch = async (value) => {
    setIsLoading(true);
    const res = await getOutletOrder(value, profile.branchCode);
    if (res.isSuccessful && res.orderList.length !== 0) {
      setOutletName(res.customerName);
      setSelectedOrder(res);
    } else {
      setSelectedOrder(RetrievedOrderDefaultType);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      centered
      closable={false}
      maskClosable={false}
      open={isOpen}
      onOk={setIsOpen}
      footer={null}
      confirmLoading={isLoading}
      keyboard={false}
    >
      <Row justify="center" align="middle">
        <Col xs={24} sm={18}>
          <Search
            loading={isLoading}
            enterButton
            size="large"
            placeholder="Please enter order number"
            value={searchValue}
            onSearch={(value) => handleSalesOrderSearch(value)}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValue(value);
              handleSalesOrderSearch(value);
            }}
            allowClear={true}
          />
        </Col>
      </Row>
      {/* <Row justify="center" align="middle" className="mb-3">
        <Col xs={24} sm={18} className="text-center">
          <Typography.Text
            type="secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => console.log('Show all pending orders')}
          >
            Show all pending orders?
          </Typography.Text>
        </Col>
      </Row> */}
      {selectedOrder.orderNumber !== '' && (
        <Card
          hoverable
          title={`Order no: ${selectedOrder.orderNumber}`}
          loading={isLoading}
          className="my-3"
          onClick={() => {
            onSelectOrder(selectedOrder, outletName);
            setSelectedOrder(RetrievedOrderDefaultType);
            setSearchValue('');
            setIsOpen(false);
          }}
        >
          <Meta
            title={`Outlet Name: ${
              selectedOrder.customerName === ' '
                ? 'Anonymous Customer'
                : selectedOrder.customerName
            }`}
            description={
              selectedOrder.orderList.length !== 0 &&
              selectedOrder.orderList.map((order, index) => (
                <Content key={index} className="ps-3 mb-2">
                  <Typography.Text strong>Name: </Typography.Text>
                  <Typography.Text>{order.productName}</Typography.Text>
                  <br />
                  <Typography.Text strong>Description: </Typography.Text>
                  <Typography.Text>{order.productDesc}</Typography.Text>
                  <br />
                  <Typography.Text strong>Qty: </Typography.Text>
                  <Typography.Text>{order.productQty}</Typography.Text>
                </Content>
              ))
            }
          />
        </Card>
      )}
      <Button
        type="primary"
        block
        danger
        className="mt-3"
        onClick={() => {
          setIsOpen(false);
          navigate(SALES_ORDER_OUTLET_ROUTE);
        }}
      >
        Close
      </Button>
      {/* For looping on all orders */}
      {/* <Card>
    {localOrderList
      .filter((record) => record.orderList.length === 0)
      .map((record) => (
        <Card.Grid style={gridStyle}>
          <Typography.Title level={3}>
            {record.orderNumber}
          </Typography.Title>
          {record.map((order) => (
            <>
              <Typography.Text strong>
                Name: {order.productName}
              </Typography.Text>
              <Typography.Text strong>
                Description: {order.productDesc}
              </Typography.Text>
              <Typography.Text strong>
                Qty: {order.productQty}
              </Typography.Text>
            </>
          ))}
        </Card.Grid>
      ))}
  </Card> */}
    </Modal>
  );
}
