import { Button, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export function getIncomingHistoryTable() {
  return [
    {
      title: 'Incoming Date',
      dataIndex: 'incomingDate',
      key: 'incomingDate',
      sorter: (a, b) => new Date(a.incomingDate) - new Date(b.incomingDate),
      fixed: 'left',
    },
    {
      title: 'Tank Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Incoming Quantity',
      dataIndex: 'productDesc',
      key: 'productDesc',
    },
    {
      title: 'Processed by',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
  ];
}
