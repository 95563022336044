import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns-tz';
import React from 'react';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  container: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  column: {
    flexGrow: 1,
    marginRight: 10,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  fieldName: {
    color: 'grey',
    fontSize: 12,
  },
  fieldValue: {
    fontSize: 14,
    color: 'black',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '33.33%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    padding: 5,
    fontSize: '6px',
  },
});

export default function DailyStocksReportPrint({ dsr, profile }) {
  const parseValue = (value) => {
    return parseFloat(value).toLocaleString();
  };

  return (
    <Document>
      <Page size={'LETTER'} style={styles.body} orientation="landscape">
        <Text style={styles.header} fixed>
          Daily Stocks Report
        </Text>
        <View style={{ ...styles.container, marginBottom: 10 }}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Print timestamp:{' '}
              <Text style={styles.fieldValue}>
                {format(new Date(), 'yyyy-MM-dd HH:mm:ss', {
                  timeZone: 'Asia/Manila',
                })}
              </Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Prepared By:{' '}
              <Text
                style={styles.fieldValue}
              >{`${profile.firstName} ${profile.lastName}`}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Processed Date: <Text style={styles.fieldValue}>{dsr.date}</Text>
            </Text>
          </View>
        </View>
        <View style={{ ...styles.container }}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Opening Grandtotal sales:{' '}
              <Text style={styles.fieldValue}>{parseValue(dsr.oldgt)}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Closing Grandtotal sales:{' '}
              <Text style={styles.fieldValue}>{parseValue(dsr.newgt)}</Text>
            </Text>
          </View>
        </View>
        <View style={{ ...styles.container, marginBottom: 20 }}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Opening inventory:{' '}
              <Text style={styles.fieldValue}>
                {parseValue(dsr.oldinventory)}
              </Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Closing inventory:{' '}
              <Text style={styles.fieldValue}>
                {parseValue(dsr.newinventory)}
              </Text>
            </Text>
          </View>
        </View>
        <View style={{ marginBottom: 15 }}>
          <View style={{ ...styles.container }}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                <Text style={styles.fieldValue}>OPR RETAIL - 102</Text>
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN KGS:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.oprRetailKg)}
                </Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN PESO:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.oprRetailSales)}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 15 }}>
          <View style={{ ...styles.container, marginBottom: 5 }}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                <Text style={styles.fieldValue}>OPR DEALER</Text>
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN KGS:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.oprDealerKg)}
                </Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN PESO:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.oprDealerSales)}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 15 }}>
          <View style={{ ...styles.container, marginBottom: 5 }}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                <Text style={styles.fieldValue}>AVE. PRICE</Text>
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                TOTAL SALES IN KGS:{' '}
                <Text style={styles.fieldValue}>{parseValue(dsr.totalKg)}</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                TOTAL SALES IN PESO:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.totalSales)}
                </Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                AVERAGE:{' '}
                <Text style={styles.fieldValue}>
                  {(
                    (parseValue(dsr.oprRetailKg) +
                      parseValue(dsr.oprDealerKg)) /
                    parseValue(dsr.totalSales)
                  ).toLocaleString()}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 15 }}>
          <View style={{ ...styles.container, marginBottom: 5 }}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                <Text style={styles.fieldValue}>RETAIL</Text>
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN KGS:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.retailrefill)}
                </Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: 15 }}>
          <View style={{ ...styles.container, marginBottom: 5 }}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                <Text style={styles.fieldValue}>TOTAL SALES</Text>
              </Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN KGS:{' '}
                <Text style={styles.fieldValue}>{parseValue(dsr.totalKg)}</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.fieldName}>
                SALES IN PESO:{' '}
                <Text style={styles.fieldValue}>
                  {parseValue(dsr.totalSales)}
                </Text>
              </Text>
            </View>
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
