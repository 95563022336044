import { Card, Col, Row, Statistic } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { getRunningInventory } from '../../utils/api/InventoryAPI';
import { getProducts } from '../../utils/api/ProductsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { MdOutlineInventory2 } from 'react-icons/md';
import { HiCash } from 'react-icons/hi';
import CountUp from 'react-countup';
import { GlobalContext } from '../../App';

const formatter = (value) => <CountUp end={value} separator="," />;

export default function Dashboard() {
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [bulletTanks, setBulletTanks] = useState([]);

  const [location, setLocation] = useState(null);

  const initializeData = async () => {
    setIsLoading(true);
    const bulletTanks = await getProducts();
    if (bulletTanks.isSuccessful) {
      const tanksWithInventory = await Promise.all(
        bulletTanks.data
          .filter((data) => data.isTank === '1')
          .map(async (tank) => {
            const inventory = await getRunningInventory(tank.id);
            tank.inventory = inventory;
            return tank;
          })
      );
      setBulletTanks(tanksWithInventory);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Dashboard',
        getAPIResponseMessage(bulletTanks.data)
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initializeData();
    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;
    //       console.log(latitude, longitude);
    //       setLocation({ latitude, longitude });
    //     },
    //     (error) => {
    //       console.error('Error getting location:', error);
    //     }
    //   );
    // }
  }, []);

  return (
    <Content>
      <Row gutter={16} className="mb-3">
        {bulletTanks.map((tank, index) => (
          <Col xs={24} sm={12} lg={8} key={index}>
            <Card hoverable loading={isLoading}>
              <Statistic
                title={tank.description}
                value={tank.inventory.Stock[0].stockQty}
                precision={0}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<MdOutlineInventory2 />}
                suffix="Kg/s"
                formatter={formatter}
              />
              <Statistic
                value={tank.inventory.stockSales[0].totalSales}
                prefix={<HiCash />}
                suffix="Total Sales"
                formatter={formatter}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Content>
  );
}
