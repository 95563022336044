import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { getSalesHistory } from '../../utils/api/ReportsAPI';
import { detailedSalesHistoryReportHeader } from '../../utils/tableData/Report/SalesHistoryReport';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { PDFViewer } from '@react-pdf/renderer';
import { PrinterFilled } from '@ant-design/icons';
import OutletDefaultType from '../../utils/types/OutletDefaultType';
import { getOutletList } from '../../utils/api/OutletAPI';
import { getOutletHistoryReportHeader } from '../../utils/tableData/Report/OutletHistoryReportHeader';
import { UserContext } from '../../utils/context/UserContext';
import { OutletHistoryPrint } from '../../components';

export default function OutletHistoryReport() {
  const { profile } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const [localSalesHistory, setLocalSalesHistory] = useState([]);

  const [selectedSalesHistory, setSelectedSalesHistory] = useState({});
  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [localOutletList, setLocalOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletDefaultType);

  const tableContents = localSalesHistory.map((data, index) => ({
    ...data,
    key: index,
    id: index,
    customer_name:
      data.customer_name === ' ' ? 'Anonymous customer' : data.customer_name,
  }));

  const handleTableActions = (id) => {
    const record = tableContents.find((value) => value.id === id);
    setSelectedSalesHistory(record);
    setIsDetailedModalOpen(true);
  };

  const onProcessRequest = async () => {
    setIsLoading(true);
    const res = await getSalesHistory(
      profile.branchCode,
      dateFrom,
      dateTo,
      '3',
      selectedOutlet.id
    );
    if (res.isSuccess) {
      if (res.salesHistory.length > 0) {
        setLocalSalesHistory(res.salesHistory);
      }
    } else {
      setLocalSalesHistory([]);
      openNotification(
        WARNING_NOTIFICATION_TYPE,
        'Outlet History',
        res.message
      );
    }
    setIsLoading(false);
  };

  const getOutlets = async () => {
    setIsLoading(true);
    const res = await getOutletList();
    if (res.isSuccessful) {
      setLocalOutletList(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Outlet history report.',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOutlets();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Outlet History</Typography.Title>
      <Row>
        <Col xs={24} sm={18}>
          <Space.Compact style={{ width: '100%' }}>
            <Select
              loading={isLoading}
              disabled={isLoading}
              showSearch
              placeholder="Search Outlet"
              onChange={(value) => {
                if (value === 'all') {
                  setSelectedOutlet({ ...OutletDefaultType, id: '0' });
                } else {
                  const outlet = localOutletList.find(
                    (user) => user.id === value
                  );
                  setSelectedOutlet(outlet);
                }
              }}
              onSearch={(value) => console.log(value)}
              filterOption={(inputValue, option) => {
                const optionDescription = option.children
                  .join(' ')
                  .toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
            >
              <Select.Option value={'all'}>All</Select.Option>
              {localOutletList.map((record, index) => (
                <Select.Option key={index} value={record.id}>
                  {record.outlet_desc}
                </Select.Option>
              ))}
            </Select>
            <DatePicker
              onChange={(_, dateString) => {
                setDateFrom(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <DatePicker
              onChange={(_, dateString) => {
                setDateTo(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <Button
              type="primary"
              disabled={
                isLoading ||
                selectedOutlet.id === null ||
                dateFrom === '' ||
                dateTo === ''
              }
              onClick={() => onProcessRequest()}
            >
              Process
            </Button>
          </Space.Compact>
        </Col>
        <Col sm={6}>
          <Button
            block
            icon={<PrinterFilled />}
            onClick={() => setIsPrintModalOpen(true)}
            disabled={localSalesHistory?.length === 0 || isLoading}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Table
        columns={getOutletHistoryReportHeader(handleTableActions)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true, y: 350 }}
        loading={isLoading}
        className="mt-3"
      />
      <Modal
        centered
        closable={false}
        open={isDetailedModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsDetailedModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'80vw'}
        title="Detailed report"
      >
        <Descriptions
          className="mt-4"
          bordered
          size="small"
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Customer">
            {selectedSalesHistory.customer_name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {selectedSalesHistory.date}
          </Descriptions.Item>
          <Descriptions.Item label="Order number">
            {selectedSalesHistory.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Total QTY">
            {selectedSalesHistory.salesQty}
          </Descriptions.Item>
          <Descriptions.Item label="Order details" span={2}>
            <Table
              columns={detailedSalesHistoryReportHeader}
              dataSource={selectedSalesHistory?.orderList?.map(
                (data, index) => ({
                  ...data,
                  key: index,
                })
              )}
              size="small"
              bordered="true"
              loading={isLoading}
            />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Modal
        centered
        closable={false}
        open={isPrintModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsPrintModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'90vw'}
      >
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <OutletHistoryPrint
            type={'3'}
            records={localSalesHistory}
            date={`${dateFrom} to ${dateTo}`}
            profile={profile}
          />
        </PDFViewer>
      </Modal>
    </Content>
  );
}
