import { Button, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export function getCustomersTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Middle Name',
      dataIndex: 'middleName',
      key: 'middleName',
      sorter: (a, b) => a.middleName.localeCompare(b.middleName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Tel Number',
      dataIndex: 'telNumber',
      key: 'telNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Marital Status',
      dataIndex: 'maritalStatus',
      key: 'maritalStatus',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id, 'edit')}
            icon={<AiFillEdit size={20} />}
            className="mx-2"
          >
            Edit
          </Button>
          {/* <Popconfirm
            title={`Are you sure to delete ${record.firstName}?`}
            onConfirm={() => handleAction(record.id, 'delete')}
          >
            <Button type="primary" danger icon={<AiFillDelete size={20} />}>
              Delete
            </Button>
          </Popconfirm> */}
        </>
      ),
    },
  ];
}
