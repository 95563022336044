import axios from 'axios';
import { API_CALL_ERROR, CATEGORIES_ENDPOINT } from './_ConstantsAPI';

export async function getCategories(requestorId, branchCode, authToken) {
  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCategoryById(
  requestorId,
  branchCode,
  customerId,
  authToken
) {
  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        customerId: customerId,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCategoryBySearch(
  requestorId,
  branchCode,
  search,
  authToken
) {
  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        search: search,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
