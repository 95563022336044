import { Button } from 'antd';

export function getSalesHistoryReportHeader(handleAction) {
  return [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a, b) => a?.customer_name?.localeCompare(b?.customer_name),
      fixed: 'left',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date - b.date,
    },

    {
      title: 'Order #',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Quantity sold',
      dataIndex: 'salesQty',
      key: 'salesQty',
      sorter: (a, b) => a.salesQty - b.salesQty,
    },
    {
      title: 'Total amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
    },
    {
      title: 'Amount paid',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      sorter: (a, b) => a.amount_paid - b.amount_paid,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id)}
            className="mx-2"
          >
            View
          </Button>
        </>
      ),
    },
  ];
}

export const detailedSalesHistoryReportHeader = [
  {
    title: 'Product Name',
    dataIndex: 'productName',
    key: 'productName',
    fixed: 'left',
  },
  {
    title: 'Description',
    dataIndex: 'productDesc',
    key: 'productDesc',
  },

  {
    title: 'Qty',
    dataIndex: 'salesQty',
    key: 'salesQty',
  },
];
