import { Button } from 'antd';

export function getOutletHistoryReportHeader(handleAction) {
  return [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a, b) => a?.customer_name?.localeCompare(b?.customer_name),
      fixed: 'left',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date - b.date,
    },

    {
      title: 'Order #',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Quantity sold',
      dataIndex: 'salesQty',
      key: 'salesQty',
      sorter: (a, b) => a.salesQty - b.salesQty,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id)}
            className="mx-2"
          >
            View
          </Button>
        </>
      ),
    },
  ];
}
