import React, { useContext, useEffect, useState } from 'react';
import { getCustomerBySearch } from '../../utils/api/CustomersAPI';
import {
  WARNING_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { GlobalContext } from '../../App';
import { Content } from 'antd/es/layout/layout';
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { PrinterFilled } from '@ant-design/icons';
import { getCustomerHistoryReportAPI } from '../../utils/api/ReportsAPI';
import { getCustomerHistoryReportHeader } from '../../utils/tableData/Report/CustomerHistoryReportHeader';
import { detailedSalesHistoryReportHeader } from '../../utils/tableData/Report/SalesHistoryReport';
import { PDFViewer } from '@react-pdf/renderer';

export default function CustomerHistoryReport() {
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [dateTo, setDateTo] = useState('');
  const [dateFrom, setDateFrom] = useState('');

  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(0);

  const [salesHistory, setSalesHistory] = useState([]);
  const [selectedSalesHistory, setSelectedSalesHistory] = useState({});

  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const tableContents = salesHistory.map((data, index) => ({
    ...data,
    key: index,
  }));

  const handleTableActions = (id) => {
    const record = tableContents.find((value) => value.id === id);
    setSelectedSalesHistory(record);
    setIsDetailedModalOpen(true);
  };

  const processCustomerHistory = async () => {
    setSalesHistory([]);
    setIsLoading(true);

    const response = await getCustomerHistoryReportAPI(
      selectedCustomer,
      dateFrom,
      dateTo
    );
    if (response.isSuccess) {
      setSalesHistory(response.salesHistory);
    } else {
      openNotification(
        WARNING_NOTIFICATION_TYPE,
        'Customer selection',
        'No record for this customer.'
      );
    }
    setIsLoading(false);
  };

  const handleCustomerSearch = async (value) => {
    if (value.length > 2) {
      setIsLoading(true);
      const res = await getCustomerBySearch(value);
      if (res.isSuccessful) {
        setCustomerList(
          res.data.map((record) => ({
            value: record.id,
            text: `${record.firstName} ${record.lastName}`,
          }))
        );
      } else {
        openNotification(
          WARNING_NOTIFICATION_TYPE,
          'Customer selection',
          getAPIResponseMessage(res.data)
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <Content>
      <Typography.Title level={3}>Customers History report</Typography.Title>
      <Row className="mb-3">
        <Col xs={24} sm={18} className="text-start">
          <Space.Compact>
            <Select
              style={{ width: '60%' }}
              size="large"
              defaultActiveFirstOption={false}
              loading={isLoading}
              showSearch
              placeholder="Search customer"
              onChange={(value) => setSelectedCustomer(value)}
              onSearch={(value) => handleCustomerSearch(value)}
              filterOption={false}
              options={(customerList || []).map((d) => ({
                value: d.value,
                label: d.text,
              }))}
            />
            <DatePicker.RangePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setDateFrom(start.format('YYYY-MM-DD'));
                setDateTo(end.format('YYYY-MM-DD'));
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <Button
              size="large"
              type="primary"
              disabled={
                isLoading ||
                dateTo === '' ||
                dateFrom === '' ||
                selectedCustomer === 0
              }
              onClick={() => processCustomerHistory()}
            >
              Process
            </Button>
          </Space.Compact>
        </Col>
        <Col sm={6} xs={24}>
          <Button
            block
            size="large"
            icon={<PrinterFilled />}
            onClick={() => setIsPrintModalOpen(true)}
            disabled={salesHistory.length === 0 || isLoading}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Table
        columns={getCustomerHistoryReportHeader(handleTableActions)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true, y: 350 }}
        loading={isLoading}
        className="mt-3"
      />
      <Modal
        centered
        closable={false}
        open={isDetailedModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsDetailedModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'80vw'}
        title="Detailed report"
      >
        <Descriptions
          className="mt-4"
          bordered
          size="small"
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Customer">
            {selectedSalesHistory.customer_name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {selectedSalesHistory.date}
          </Descriptions.Item>
          <Descriptions.Item label="Order number">
            {selectedSalesHistory.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Total QTY">
            {selectedSalesHistory.salesQty}
          </Descriptions.Item>
          <Descriptions.Item label="Price per KG">
            {selectedSalesHistory.price_per_kg}
          </Descriptions.Item>
          <Descriptions.Item label="Total Amount">
            {selectedSalesHistory.total_amount}
          </Descriptions.Item>
          <Descriptions.Item label="Paid amount">
            {selectedSalesHistory.amount_paid}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {selectedSalesHistory.transtype}
          </Descriptions.Item>
          <Descriptions.Item label="Order details" span={2}>
            <Table
              columns={detailedSalesHistoryReportHeader}
              dataSource={selectedSalesHistory?.orderList?.map(
                (data, index) => ({
                  ...data,
                  key: index,
                })
              )}
              size="small"
              bordered="true"
              loading={isLoading}
            />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Modal
        centered
        closable={false}
        open={isPrintModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsPrintModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'90vw'}
      >
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          {/* <CheckerHistoryPrint
            selectedChecker={selectedChecker}
            type={'3'}
            records={localSalesHistory}
            date={`${dateFrom} to ${dateTo}`}
            profile={profile}
          /> */}
        </PDFViewer>
      </Modal>
    </Content>
  );
}
