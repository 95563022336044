export const getExpenseRequestFields = (
  isLoading,
  expenseRequestState,
  setExpenseRequestState,
  categoryOptions
) => {
  return [
    {
      type: 'dropdown',
      name: 'expenscat',
      placeholder: 'Please select category!',
      label: 'Expense category',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: expenseRequestState.expenscat,
      dropdownOptions: categoryOptions,
      setValue: (value) => {
        const categoryDesc = categoryOptions.find((cat) => cat.id === value);
        setExpenseRequestState({
          ...expenseRequestState,
          expenscat: value,
          category: categoryDesc.description,
        });
      },
    },
    {
      type: 'text',
      name: 'voucher',
      placeholder: 'Please enter voucher!',
      label: 'Voucher',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: expenseRequestState.voucher,
      setValue: (e) => {
        const value = e.target.value;
        setExpenseRequestState({ ...expenseRequestState, voucher: value });
      },
    },
    {
      type: 'price',
      name: 'price',
      placeholder: 'Please enter amount!',
      label: 'Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: expenseRequestState.amount,
      setValue: (e) => {
        const value = e.target.value;
        setExpenseRequestState({ ...expenseRequestState, amount: value });
      },
    },
    {
      type: 'textarea',
      name: 'details',
      placeholder: 'Please enter details!',
      label: 'Details',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: expenseRequestState.expensedesc,
      setValue: (e) => {
        const value = e.target.value;
        setExpenseRequestState({ ...expenseRequestState, expensedesc: value });
      },
    },
  ];
};
