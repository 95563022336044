import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import ProductDefaultType from '../../utils/types/ProductDefaultType';
import {
  addProduct,
  deleteProduct,
  getProducts,
  updateProduct,
} from '../../utils/api/ProductsAPI';
import { IoIosRefresh } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { getProductsTable } from '../../utils/tableData/Maintenance/ProductsTable';
import { getProductMaintenanceFields } from '../../utils/data/ProductField';
import { FormInput } from '../../components';
import { getCategories } from '../../utils/api/CategoriesAPI';
import { GlobalContext } from '../../App';
import Search from 'antd/es/input/Search';

export default function ProductsMaintenance() {
  const { authToken, openNotification } = useContext(GlobalContext);
  const { profile, formItemLayout } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentProductState, setCurrentProductState] =
    useState(ProductDefaultType);
  const [localProductsList, setLocalProductsList] = useState([]);
  const [localCategoryList, setLocalCategoryList] = useState([]);

  const [tableHeaderFilter, setTableHeaderFilter] = useState('');
  const [tableFilter, setTableFilter] = useState('');

  let tableContents = localProductsList
    .filter((data) => data.isTank !== '1')
    .filter((data) => {
      if (tableHeaderFilter === '') {
        return data;
      }
      return data[tableHeaderFilter]
        .toString()
        .toLowerCase()
        .includes(tableFilter.toLowerCase());
    })
    .map((data, index) => ({
      ...data,
      key: index,
    }));

  const getParseProductData = () => {
    const parseData = {
      id: currentProductState.id,
      branch: profile.branchCode,
      code: currentProductState.code,
      name: currentProductState.name,
      description: currentProductState.description,
      category: currentProductState.categoryId,
      qtyKg: currentProductState.quantity,
      price: currentProductState.price,
      isTank: 0,
      addedBy: profile.id,
      updatedBy: profile.id,
    };
    return parseData;
  };

  const handleAction = async (id, action) => {
    setIsLoading(true);
    if (action === 'edit') {
      const product = localProductsList.find((prod) => prod.id === id);
      fetchCategoryList();
      setCurrentProductState({ ...product });
      form.setFieldsValue(product);
      setIsModalOpen(true);
    } else if (action === 'delete') {
      const res = await deleteProduct({
        id: id,
      });
      if (res.isSuccess) {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Products',
          'Product successfully deleted.'
        );
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Products',
          'There was a problem deleting the product.'
        );
      }
    }
    setIsLoading(false);
  };

  const handleModalSave = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      if (currentProductState.id === '') {
        // New product flow
        const res = await addProduct(getParseProductData());
        if (res.isSuccess) {
          setIsModalOpen(false);
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Products',
            'Product successfully added.'
          );
          fetchProductsList();
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Products',
            getAPIResponseMessage(res.message)
          );
        }
      } else {
        // Edit product flow
        const res = await updateProduct(getParseProductData());
        if (res.isSuccess) {
          setIsModalOpen(false);
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Products',
            'Product successfully updated.'
          );
          fetchProductsList();
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Products',
            'There was a problem updating the product.'
          );
        }
      }
    } catch (error) {
      // No action needed.
    }
    setIsLoading(false);
  };

  const fetchProductsList = async () => {
    setIsLoading(true);
    resetData();
    const res = await getProducts();
    if (res.isSuccessful) {
      setLocalProductsList(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Products',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const fetchCategoryList = async () => {
    setIsLoading(true);
    const res = await getCategories(profile.id, profile.branchCode, authToken);
    if (res.isSuccessful) {
      setLocalCategoryList(
        res.data.map((cat) => ({
          id: cat.id,
          description: cat.description,
        }))
      );
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Products',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const resetData = () => {
    setTableHeaderFilter('');
    setTableFilter('');
    setCurrentProductState(ProductDefaultType);
    form.setFieldsValue(ProductDefaultType);
  };

  useEffect(() => {
    if (localProductsList.length === 0) {
      fetchProductsList();
    }
  }, []);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      fetchProductsList();
    }, 1800000);

    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  return (
    <Content>
      <Row align={'middle'} className="mb-4">
        <Col xs={24} md={12}>
          <Typography.Title level={3}>Products Maintenance</Typography.Title>
        </Col>
        <Col xs={24} md={12} className="text-end">
          <Button
            type="primary"
            size="large"
            icon={<IoIosRefresh size={24} />}
            loading={isLoading}
            onClick={() => fetchProductsList()}
          />
          <Button
            type="primary"
            size="large"
            icon={<AiOutlinePlus size={24} />}
            loading={isLoading}
            className="ms-3"
            onClick={async () => {
              resetData();
              await fetchCategoryList();
              setIsModalOpen(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Space.Compact>
          <Select
            style={{ minWidth: '10vw' }}
            placeholder="Filters"
            value={tableHeaderFilter}
            onChange={(e) => {
              setTableHeaderFilter(e);
            }}
            disabled={isLoading}
            options={getProductsTable(null)
              .filter((header) => header.key !== 'actions')
              .map((option) => ({
                label: option.title,
                value: option.key,
              }))}
          />
          <Search
            placeholder="Search for product"
            disabled={isLoading || tableHeaderFilter === ''}
            value={tableFilter}
            onSearch={(value) => {
              setTableFilter(value);
            }}
            onChange={(e) => {
              const searchValue = e.target.value;
              setTableFilter(searchValue);
            }}
            allowClear={true}
          />
        </Space.Compact>
      </Row>
      <Table
        columns={getProductsTable(handleAction)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <Modal
        title="Product Entry"
        centered
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleModalSave}
        okText={'Save'}
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="product_form"
          {...formItemLayout}
          initialValues={currentProductState}
        >
          {getProductMaintenanceFields(
            isLoading,
            currentProductState,
            setCurrentProductState,
            localCategoryList
          ).map((data, index) => (
            <FormInput
              key={index}
              type={data.type}
              name={data.name}
              placeholder={data.placeholder}
              label={data.label}
              autoFocus={data.autofocus}
              value={data.value}
              onChange={data.setValue}
              required={data.required}
              disabled={data.disabled}
              dropdownOptions={data.dropdownOptions}
            />
          ))}
        </Form>
      </Modal>
    </Content>
  );
}
