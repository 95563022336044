export default {
  date: null,
  retailSales: null,
  wholesaleSales: null,
  totalSales: null,
  newgt: null,
  newgtinv: null,
  oldgt: null,
  oldinv: null,
  oprR: null,
  oprD: null,
  retailrefill: null,
  add: null,
  less: null,
};
