import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_ORDER_ENDPOINT,
  POST_END_OF_DAY_ENDPOINT,
  POST_ORDER_ENDPOINT,
  POST_TENDER_DECLARATION_ENDPOINT,
  PROCESS_END_OF_DAY_ENDPOINT,
  SAVE_ORDER_ENDPOINT,
} from './_ConstantsAPI';

export async function saveOrder(params) {
  const payload = {
    data: JSON.stringify(params),
  };
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(SAVE_ORDER_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getOrder(orderNumber, branchId, transType) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.get(
      `${GET_ORDER_ENDPOINT}/${orderNumber}/${branchId}/${transType}`,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postOrder(params) {
  const payload = {
    data: JSON.stringify(params),
  };
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(POST_ORDER_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function processEODApi(date, branch) {
  try {
    const { data } = await axios.get(
      `${PROCESS_END_OF_DAY_ENDPOINT}/${date}/${branch}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postEODApi(payload) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(POST_END_OF_DAY_ENDPOINT, payload, {
      headers,
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postTenderDeclaration(payload) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(
      POST_TENDER_DECLARATION_ENDPOINT,
      payload,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
