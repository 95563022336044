export const getTenderDeclarationFirstColumnFields = (
  isLoading,
  tenderDeclarationState,
  setTenderDeclarationState
) => {
  return [
    {
      type: 'number',
      name: 'thousands',
      placeholder: '1000',
      label: '1000',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.thousands,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          thousands: value,
        });
      },
    },
    {
      type: 'number',
      name: 'fiveHundreds',
      placeholder: '500',
      label: '500',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.fiveHundreds,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          fiveHundreds: value,
        });
      },
    },
    {
      type: 'number',
      name: 'twoHundreds',
      placeholder: '200',
      label: '200',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.twoHundreds,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          twoHundreds: value,
        });
      },
    },
    {
      type: 'number',
      name: 'oneHundreds',
      placeholder: '100',
      label: '100',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.oneHundreds,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          oneHundreds: value,
        });
      },
    },
    {
      type: 'number',
      name: 'fifties',
      placeholder: '50',
      label: '50',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.fifties,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          fifties: value,
        });
      },
    },
    {
      type: 'number',
      name: 'twenties',
      placeholder: '20',
      label: '20',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.twenties,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          twenties: value,
        });
      },
    },
  ];
};

export const getTenderDeclarationSecondColumnFields = (
  isLoading,
  tenderDeclarationState,
  setTenderDeclarationState
) => {
  return [
    {
      type: 'number',
      name: 'tens',
      placeholder: '10',
      label: '10',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.tens,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          tens: value,
        });
      },
    },
    {
      type: 'number',
      name: 'fives',
      placeholder: '5',
      label: '5',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.fives,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          fives: value,
        });
      },
    },
    {
      type: 'number',
      name: 'peso',
      placeholder: '1',
      label: '1',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.peso,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          peso: value,
        });
      },
    },
    {
      type: 'number',
      name: 'twentyFiveCents',
      placeholder: '25¢',
      label: '25¢',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.twentyFiveCents,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          twentyFiveCents: value,
        });
      },
    },
    {
      type: 'number',
      name: 'fiveCents',
      placeholder: '5¢',
      label: '5¢',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.fiveCents,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          fiveCents: value,
        });
      },
    },
    {
      type: 'number',
      name: 'oneCent',
      placeholder: '1¢',
      label: '1¢',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: tenderDeclarationState.oneCent,
      setValue: (e) => {
        const value = e.target.value;
        setTenderDeclarationState({
          ...tenderDeclarationState,
          oneCent: value,
        });
      },
    },
  ];
};
