import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_SOLID_ASSETS_PRODUCTS_ENDPOINT,
  POST_SOLID_ASSETS_TRANSACTION_ENDPOINT,
} from './_ConstantsAPI';

export async function getSolidAssetsProducts() {
  try {
    const { data } = await axios.get(GET_SOLID_ASSETS_PRODUCTS_ENDPOINT);
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postSolidAssetTransaction(payload) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.post(
      POST_SOLID_ASSETS_TRANSACTION_ENDPOINT,
      payload,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
