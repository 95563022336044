import { Col, InputNumber, Modal, Row, Space, Typography, message } from 'antd';
import React, { useContext } from 'react';
import { useState } from 'react';
import { GlobalContext } from '../../App';
import { WARNING_NOTIFICATION_TYPE } from '../../utils/api/_ConstantsAPI';

export default function PaymentModal({
  isOpen,
  setIsOpen,
  amountToPay,
  handleOnSuccessPayment,
}) {
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [tenderedAmount, setTenderedAmount] = useState(0);

  return (
    <>
      <Modal
        centered
        closable={false}
        maskClosable={false}
        open={isOpen}
        keyboard={false}
        onOk={() => {
          if (tenderedAmount >= amountToPay) {
            handleOnSuccessPayment(tenderedAmount);
            setIsOpen(false);
          } else {
            openNotification(
              WARNING_NOTIFICATION_TYPE,
              'Payment',
              'Insufficient amount!'
            );
          }
        }}
        confirmLoading={isLoading}
        onCancel={() => setIsOpen(false)}
        okText="Pay"
      >
        <Typography.Title level={2}>
          Please pay amount of: &#8369;{amountToPay}
        </Typography.Title>
        <InputNumber
          min={0}
          // max={parseInt(originalPricePerKg)}
          type="number"
          controls={false}
          value={tenderedAmount}
          style={{
            width: '100%',
            fontSize: '30px',
          }}
          onChange={(value) => {
            setTenderedAmount(value);
          }}
          className="mt-2 py-1"
        />
      </Modal>
    </>
  );
}
